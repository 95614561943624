import React from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import gsap from 'gsap'
import { scroller } from 'react-scroll'
import HamburgerNav from './HamburgerNav'
import MenuLinks from './MenuLinks'

const Menu = ({ location, previousLocation }) => {
  const menuColor = 'white'
  const backgroundColor = '#1c1c20'

  const Header = styled.header`
    width: 100%;
    padding: 0 0 0 0;
    animation: fadeInNav 0.9s ease-in-out 1.2s forwards;
    opacity: 0;
    position: relative;
    z-index: 200;

    @keyframes fadeInNav {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .logoContainer {
      width: 30px;
    }

    .hamBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0 25px 0;
    }

    .navBar {
      width: 94vw;
      max-width: 1800px;
      margin: 0 auto;
    }

    @media (max-width: 840px) {
      .navBar {
        width: 90vw;
      }

      .hamBar {
        padding: 15px 0 15px 0;
      }
    }

    @media (max-width: 450px) {
      .hamBar {
        padding: 15px 0 15px 0;
      }
    }
  `

  const Nav = styled.nav`
    ul {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 25px 0;
    }

    li {
      display: flex;
      align-items: center;
      margin-left: calc(6%);
    }

    .homeLink {
      margin-right: auto;
      margin-left: 0;
    }

    a {
      text-decoration: none;
      color: ${menuColor};
      font-size: 0.9rem;
    }

    @media (max-width: 840px) {
      display: none;
    }
  `

  const NavInfo = [
    {
      href: '/#selectedWorks',
      scroll: 'selectedWorks',
      className: null,
      name: `Selected Works`,
    },
    {
      href: '/#services',
      scroll: 'services',
      className: null,
      name: `Services`,
    },
    {
      href: '/#contactUs',
      scroll: 'contactUs',
      className: null,
      name: `Contact`,
    },
  ]

  const triggerTransition = (timeline, target) => {
    timeline
      .to(target, {
        transform: `translateY(0vh)`,
        display: 'flex',
        duration: 0,
      })
      .to(target, {
        delay: '0.2',
        transform: 'translateY(-100vh)',
        duration: 0.5,
        display: 'flex',
      })
  }

  const handleNavigate = (destination, scroll) => {
    previousLocation.current = location.pathname

    const swiper = document.getElementById('swiperMain')

    let tl = gsap.timeline({
      onComplete: () => {
        navigate(destination, {
          state: { scrollTo: scroll },
        })
      },
    })
    triggerTransition(tl, swiper)
  }

  const handleScroll = destination => {
    scroller.scrollTo(destination, {
      delay: 100,
      smooth: true,
    })
  }

  return (
    <Header>
      <Nav id={`mainNav`} name="top" className={`navBar`}>
        <ul className="loginBorder">
          <MenuLinks
            NavInfo={NavInfo}
            currentLocation={location.pathname}
            handleScroll={handleScroll}
            handleNavigate={handleNavigate}
            menuOpen={null}
            handleClose={null}
          />
        </ul>
      </Nav>

      <HamburgerNav
        NavInfo={NavInfo}
        handleScroll={handleScroll}
        handleNavigate={handleNavigate}
        currentLocation={location.pathname}
        menuColor={menuColor}
        backgroundColor={backgroundColor}
      />
    </Header>
  )
}

export default Menu
