export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {}

const setUser = user =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

export const handleLogin = ({ username }, data) => {

  data.forEach(post => {
    if (username === post.node.password) {
      return setUser({
        username: post.node.password,
      })
    }
  })

  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}
