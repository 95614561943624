import React from 'react'
import styled from '@emotion/styled'

const LinkFlip = ({ word }) => {
  const LinkContainer = styled.div`
    overflow-y: hidden;

    .flip {
      transition: transform 0.3s ease-in-out;
      position: relative;
      line-height: 2.3;

      &:after {
        content:'${word}';
        position: absolute;
        top: 100%;
        left: 0;
        color: white;
        height: 150%;
        line-height: 2.3;

      }

      &:hover {
        transform: translateY(-100%);
      }
    }
  `

  return (
    <LinkContainer>
      <div className="flip">{word}</div>
    </LinkContainer>
  )
}

export default LinkFlip
