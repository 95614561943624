import { css } from '@emotion/core'
import GzaWoff from '../assets/fonts/gza/Gza-Seminegra.woff'
import GzaWoff2 from '../assets/fonts/gza/Gza-Seminegra.woff2'

import neueHaasGroteskRegWoff from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-55Rg.woff'
import neueHaasGroteskRegWoff2 from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-55Rg.woff2'

import neueHaasGroteskBoldWoff from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-75Bd.woff'
import neueHaasGroteskBoldWoff2 from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-75Bd.woff2'

import neueHaasGroteskItalicWoff from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-56It.woff'
import neueHaasGroteskItalicWoff2 from '../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-56It.woff2'

import neueHaasGroteskBlackWoff from '../assets/fonts/neuehaasgrotesk/NHaasGroteskDSStd-95Blk.woff'
import neueHaasGroteskBlackWoff2 from '../assets/fonts/neuehaasgrotesk/NHaasGroteskDSStd-95Blk.woff2'

import suisseintlLightWoff from '../assets/fonts/suisseintl/SuisseIntl-Light.woff'
import suisseintlLightWoff2 from '../assets/fonts/suisseintl/SuisseIntl-Light.woff2'

import suisseintlSemiBoldWoff from '../assets/fonts/suisseintl/SuisseIntl-SemiBold.woff'
import suisseintlSemiBoldWoff2 from '../assets/fonts/suisseintl/SuisseIntl-SemiBold.woff2'

import suisseintlThinWoff from '../assets/fonts/suisseintl/SuisseIntl-Thin.woff'
import suisseintlThinWoff2 from '../assets/fonts/suisseintl/SuisseIntl-Thin.woff2'

import suisseintlNormalWoff from '../assets/fonts/suisseintl/SuisseIntl.woff'
import suisseintlNormalWoff2 from '../assets/fonts/suisseintl/SuisseIntl.woff2'

export const globalStyles = css`
  /* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* stylelint-disable-next-line */
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */

  html,
  body {
    height: 100%;
    background-color: #1c1c20;
    min-height: 100vh;
    font-family: 'SuisseIntl', sans-serif;
  }

  html {
    width: 100%;
    overflow-x: hidden;
  }

  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  body {
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    font-weight: 400;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  button,
  input,
  textarea,
  select {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:invalid {
      box-shadow: none;
    }
    &:focus {
      outline: 5px auto #5e9ed6;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }

  html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  figcaption,
  figure,
  main {
    display: block;
  }
  figure {
    margin: 1em 40px;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: inherit;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  dfn {
    font-style: italic;
  }
  mark {
    color: #000;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  audio,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  img {
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    display: inline-block;
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details,
  menu {
    display: block;
  }
  summary {
    display: list-item;
  }
  canvas {
    display: inline-block;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '';
    clear: both;
    height: 0;
  }

  html {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .visuallyHidden:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(00 0 0);
    overflow: hidden;
  }

  @font-face {
    font-family: 'NHaasGroteskTXStd-55Rg';
    src: url('../../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-55Rg.woff2')
        format('woff2'),
      url('../../assets/fonts/neuehaasgrotesk/NHaasGroteskTXStd-55Rg.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gza';
    src: url(${GzaWoff2}) format('woff2'), url(${GzaWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasGrotesk';
    src: url(${neueHaasGroteskBlackWoff2}) format('woff2'),
      url(${neueHaasGroteskBlackWoff}) format('woff');
    font-weight: 1000;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasGrotesk';
    src: url(${neueHaasGroteskItalicWoff2}) format('woff2'),
      url(${neueHaasGroteskItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'NeueHaasGrotesk';
    src: url(${neueHaasGroteskBoldWoff2}) format('woff2'),
      url(${neueHaasGroteskBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasGrotesk';
    src: url(${neueHaasGroteskRegWoff2}) format('woff2'),
      url(${neueHaasGroteskRegWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SuisseIntl';
    src: url(${suisseintlNormalWoff2}) format('woff2'),
      url(${suisseintlNormalWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SuisseIntl';
    src: url(${suisseintlLightWoff2}) format('woff2'),
      url(${suisseintlLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SuisseIntl';
    src: url(${suisseintlThinWoff2}) format('woff2'),
      url(${suisseintlThinWoff}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'SuisseIntl';
    src: url(${suisseintlSemiBoldWoff2}) format('woff2'),
      url(${suisseintlSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  h1,
  h2,
  h3 {
    font-family: 'NeueHaasGrotesk', 'SuisseIntl', sans-serif;
  }

  h1,
  h2,
  h3,
  a,
  li,
  p {
    color: white;
  }

  a,
  li,
  p {
    font-weight: 300;
    line-height: 1.2;
  }

  .loginToggle {
    color: black !important;

    &:hover {
      text-shadow: 0 0 0.8px rgba(0, 0, 0, 0.2), 0 0 0.8px rgba(0, 0, 0, 0.2) !important;
    }
  }

  .videoContainer {
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`
