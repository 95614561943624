import React from 'react'
import { isLoggedIn, logout } from '../services/auth'
import Logo1 from '../assets/images/frontierNew.inline.svg'
import LinkFlip from './LinkFlip'

const MenuLinks = ({
  NavInfo,
  currentLocation,
  handleScroll,
  handleNavigate,
  menuOpen,
  setMenuOpen,
  handleClose,
  // logo,
  hamburger,
}) => {
  const handleClick = () => {
    setMenuOpen(false)
    handleClose()
  }

  const handleDestination = (destination, section) => {
    if (handleClose) {
      handleClick()

      setTimeout(() => {
        if (currentLocation === destination) {
          logout(() => handleScroll(section))
        } else {
          logout(() => handleNavigate(destination, section))
        }
      }, 1000)
    } else {
      if (currentLocation === destination) {
        logout(() => handleScroll(section))
      } else {
        logout(() => handleNavigate(destination, section))
      }
    }
  }

  return (
    <>
      {!hamburger ? (
        <li className="homeLink">
          <a
            href={'/'}
            className={`loginMenu`}
            onClick={event => {
              event.preventDefault()

              handleDestination('/', 'top')
            }}
          >
            <div className="logoContainer">
              <Logo1 />
            </div>
          </a>
        </li>
      ) : null}

      {NavInfo.map((link, i) => {
        return (
          <li key={i} className={link.className || null}>
            <a
              href={link.href}
              className={`loginMenu`}
              onClick={event => {
                event.preventDefault()
                handleDestination('/', link.scroll)
              }}
            >
              <LinkFlip word={link.name} />
            </a>
          </li>
        )
      })}

      <li>
        <a
          href="/partnernetwork/"
          className={`loginMenu`}
          onClick={event => {
            event.preventDefault()
            handleDestination('/partnernetwork/', 'partnernetwork')
          }}
        >
          <LinkFlip word={`Partner Network`} />
        </a>
      </li>

      {isLoggedIn() ? (
        <li>
          <a
            href="/app/"
            className={`loginMenu`}
            onClick={event => {
              event.preventDefault()
              handleDestination('/', 'top')
            }}
          >
            <LinkFlip word={`Logout`} />
          </a>
        </li>
      ) : (
        <li>
          <a
            href="/app/"
            className={`loginMenu`}
            onClick={event => {
              event.preventDefault()
              handleDestination('/app/', 'top')
            }}
          >
            <LinkFlip word={`Client Login`} />
          </a>
        </li>
      )}
    </>
  )
}

export default MenuLinks
