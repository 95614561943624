import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const Footer = () => {
  const [year, setYear] = useState(null)
  useEffect(() => {
    setYear(new Date().getYear() + 1900)
  }, [])

  const Wrapper = styled.footer``

  const List = styled.ul`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    padding: 5vh 0;

    margin: 0 auto;
    width: 94vw;
    max-width: 1800px;

    // animation: fadeInFooter 0.9s ease-in-out 1.2s forwards;
    // opacity: 0;

    // @keyframes fadeInFooter {
    //   0% {
    //     opacity: 0;
    //   }
    //   100% {
    //     opacity: 1;
    //   }
    // }

    @media (max-width: 840px) {
      width: 90vw;
    }
  `

  const Item = styled.li`
    display: inline-block;
    line-height: 1.5;
    font-size: 0.9rem;

    color: white;

    span {
      display: block;
    }
  `

  return (
    <Wrapper>
      <List className={`loginBorder`}>
        <Item className={`loginText`}>
          <span>Los Angeles</span> <span>New York City</span>{' '}
          <span>Toronto</span>
        </Item>
        <Item className={`loginText`}>
          Frontier Digital, <span>&copy;{year}</span>
        </Item>
      </List>
    </Wrapper>
  )
}

export default Footer
