import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { logout } from '../services/auth'
import MenuLinks from './MenuLinks'
import gsap from 'gsap'
import Logo2 from '../assets/images/frontierNew.inline.svg'

const HamburgerNav = ({
  handleScroll,
  handleNavigate,
  currentLocation,
  menuColor,
  backgroundColor,
}) => {
  let menuRef = useRef(null)
  let hamburgerRef = useRef(null)
  let topBarRef = useRef(null)
  let midBarRef = useRef(null)
  let lowBarRef = useRef(null)

  let menuOpen = false

  const setMenuOpen = status => {
    menuOpen = status
  }

  let menuBelow450 = false

  const handleClose = () => {
    const tl = gsap.timeline({
      onComplete: () => {
        hamburgerRef.style.position = 'static'
        hamburgerRef.style.left = 'calc(90vw - 30px + ((100% - 90vw) / 2))'
      },
    })

    gsap.to(menuRef, { x: 0, duration: 1 })
    tl.to(hamburgerRef, {
      left: 'calc(90vw - 30px + ((100% - 90vw) / 2))',
      duration: 1,
    })

    topBarRef.style.transform = 'rotate(0)'
    lowBarRef.style.opacity = '1'
    midBarRef.style.transform = 'rotate(0)'
    lowBarRef.style.transform = 'rotate(0)'
  }

  const handleNav = mediaQuery => {
    if (mediaQuery.matches) {
      setMenuOpen(false)

      handleClose()
    }
  }

  const handleExpand = mediaQuery => {
    if (mediaQuery.matches) {
      menuBelow450 = true

      gsap.to(menuRef, { width: '100vw', duration: 1 })
    } else if (!mediaQuery.matches) {
      menuBelow450 = false

      gsap.to(menuRef, { width: '350px', duration: 1 })
    }

    if (mediaQuery.matches && menuOpen === true) {
      gsap.to(menuRef, { x: '-100vw', duration: 1 })
      gsap.to(hamburgerRef, { left: `11vw`, duration: 1 })
    } else if (!mediaQuery.matches && menuOpen === true) {
      gsap.to(menuRef, { x: '-350px', duration: 1 })
      gsap.to(hamburgerRef, { left: 'calc(100vw - 312px)', duration: 0.8 })
    }
  }

  useEffect(() => {
    const switchNav = window.matchMedia('(min-width: 840px)')
    handleNav(switchNav)
    switchNav.addListener(handleNav)

    const expand = window.matchMedia('(max-width: 450px)')
    handleExpand(expand)
    expand.addListener(handleExpand)

    return () => {
      switchNav.removeListener(handleNav)
      expand.removeListener(handleExpand)
    }
  }, [])

  const HamburgerNav = styled.nav`
    display: none;
    @media (max-width: 840px) {
      display: block;
    }
    .hamBar {
      position: relative;
      .logoContainer {
        width: 30px;
      }
    }
  `

  const HamburgerIcon = styled.div`
    animation: fadeInHam 0.6s ease-in-out forwards;
    @keyframes fadeInHam {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: calc(90vw - 30px + ((100% - 90vw) / 2));
    z-index: 1000;
    div {
      transition: all 0.6s;
      width: 100%;
      height: 1px;
      background-color: ${menuColor};
    }
  `

  const HamburgerMenu = styled.div`
    background-color: ${backgroundColor};
    height: 100vh;
    width: 350px;
    position: fixed;
    z-index: 10;
    padding: 20px 0 0 0;
    left: 100vw;
    top: 0;
    display: flex;
    .leftMenu {
      border-left: 1px solid ${menuColor};
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0 40px 0;
      font-size: 1.4rem;
      text-transform: uppercase;
      h3 {
        transform: rotate(-90deg) translateY(10px);
        white-space: nowrap;
        color: ${menuColor};
      }
    }
    .rightMenu {
      width: 75%;
      display: flex;
      flex-direction: column;
      padding: 0 0 40px 1rem;
      font-size: 1.3rem;
      li:first-child a {
        font-weight: 1000;
      }
      li {
        margin-bottom: 15px;
        font-size: 1.2rem;
        a {
          color: ${menuColor};
          text-decoration: none;
          transition: all 0.6s;
        }
      }
      li:last-child {
        margin-top: auto;
        margin-bottom: 0;
      }
      li:hover a {
        font-weight: 1000;
      }
    }
    @media (max-width: 840px) {
      padding: 10px 0 0 0;
    }
  `

  const HamburgerNavInfo = [
    {
      href: '/',
      className: 'homeLink',
      scroll: 'top',
      name: 'Main',
    },
    {
      href: '/#selectedWorks',
      scroll: 'selectedWorks',
      className: null,
      name: `Selected Works`,
    },
    {
      href: '/#services',
      scroll: 'services',
      className: null,
      name: `Services`,
    },
    {
      href: '/#contactUs',
      scroll: 'contactUs',
      className: null,
      name: `Contact`,
    },
  ]

  return (
    <HamburgerNav>
      <div className="hamBar navBar">
        <a
          href={'/'}
          onClick={event => {
            event.preventDefault()

            if (currentLocation === `/`) {
              logout(() => handleScroll('top'))
            } else {
              logout(() => handleNavigate(`/`, 'top'))
            }
          }}
        >
          <div className="logoContainer">
            <Logo2 />
          </div>
        </a>
        <HamburgerIcon
          id={`hamburgerIcon`}
          ref={div => {
            hamburgerRef = div
          }}
          onClick={() => {
            setMenuOpen(!menuOpen)

            if (menuOpen) {
              hamburgerRef.style.position = 'fixed'

              // if menu is smaller than 450px, then animate accordingly

              if (menuBelow450) {
                console.log('below 450')
                gsap.to(menuRef, { x: '-100vw', duration: 1 })
                gsap.to(hamburgerRef, { left: '11vw', duration: 1 })
              } else {
                console.log('above 450')

                gsap.to(menuRef, { x: '-350px', duration: 1 })
                gsap.to(hamburgerRef, {
                  left: `calc(100vw - 312px)`,
                  duration: 1,
                })
              }

              topBarRef.style.transform =
                'rotate(-135deg) translateX(-6.8px) translateY(-6.8px)'
              lowBarRef.style.opacity = '0'
              midBarRef.style.transform = 'rotate(315deg)'
              lowBarRef.style.transform =
                'rotate(135deg) translateX(-7px) translateY(7px)'
            } else {
              handleClose()
            }
          }}
        >
          <div
            className={`bar`}
            id={`topBar`}
            ref={div => {
              topBarRef = div
            }}
          ></div>
          <div
            className={`bar`}
            id={`midBar`}
            ref={div => {
              midBarRef = div
            }}
          ></div>
          <div
            className={`bar`}
            id={`lowBar`}
            ref={div => {
              lowBarRef = div
            }}
          ></div>
        </HamburgerIcon>
      </div>
      <HamburgerMenu
        id={`hamburgerMenu`}
        ref={div => {
          menuRef = div
        }}
      >
        <div className="leftMenu">
          <h3>Frontier Digital</h3>
        </div>
        <ul className="rightMenu">
          <MenuLinks
            NavInfo={HamburgerNavInfo}
            currentLocation={currentLocation}
            handleScroll={handleScroll}
            handleNavigate={handleNavigate}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            handleClose={handleClose}
            hamburger
          />
        </ul>
      </HamburgerMenu>
    </HamburgerNav>
  )
}

export default HamburgerNav
