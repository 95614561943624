import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { globalStyles } from '../styles/globalStyles.js'
import { ParallaxProvider } from 'react-scroll-parallax'
import Transition from '../components/transition/Transition'
import Cursor from '../components/Cursor'

const Layout = ({ children, location }) => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }

  let previousLocation = useRef()

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab)

    return () => {
      window.removeEventListener('keydown', handleFirstTab)
    }
  }, [])

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      location: location,
      previousLocation: previousLocation,
    })
  )

  return (
    <ParallaxProvider>
      <Transition location={location} previousLocation={previousLocation}>
        <Root className="siteRoot" id="siteRoot">
          <Skip href="#main" id="skip-navigation">
            Skip to content
          </Skip>

          <div className="siteContent">
            <Menu location={location} previousLocation={previousLocation} />
            {childrenWithProps}
            <Footer location={location} />
          </div>
          <Cursor />

          <Global styles={globalStyles} />
        </Root>
      </Transition>
    </ParallaxProvider>
  )
}

export default Layout

const Root = styled.div`
  position: relative;

  width: 100%;

  .siteContent {
    overflow-x: hidden;
  }
`

const Skip = styled.a`
  font-family: Helvetica, serif;
  padding: 0 1rem;
  line-height: 60px;
  background: #1c1c20;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`
