import React, { createRef } from 'react'
import styled from '@emotion/styled'
import gsap from 'gsap'

import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'

const getTransitionStyles = {
  entering: {},
  entered: {},
  exited: {},
}

const appElements = {
  exiting: {
    display: 'none',
  },
}

class Transition extends React.PureComponent {
  constructor(props) {
    super(props)

    this.swiperRef = createRef()
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { children, location, previousLocation } = this.props

    return (
      <TransitionContainer>
        <TransitionGroup>
          <ReactTransition key={location.pathname} timeout={2000}>
            {state => {

              if (
                state === 'exited' &&
                location.pathname !== previousLocation.current
              ) {
                let tlSwipe = gsap.timeline({})

                tlSwipe
                  .set(this.swiperRef, { transform: 'translateY(-100vh)' })
                  .to(this.swiperRef, {
                    transform: 'translateY(-200vh)',
                    duration: 1,
                    display: 'flex',
                    delay: '1',
                  })
              }

              return (
                <div
                  className="mainTransition"
                  style={{
                    ...getTransitionStyles[state],
                  }}
                >
                  <div
                    id={`mainContainer`}
                    className="mainContainer"
                    style={{ ...appElements[state] }}
                  >
                    {children}
                  </div>
                  <div
                    className="swiperMain"
                    id="swiperMain"
                    ref={div => {
                      this.swiperRef = div
                    }}
                  >
                    <h1>Frontier Digital</h1>
                  </div>
                </div>
              )
            }}
          </ReactTransition>
        </TransitionGroup>
      </TransitionContainer>
    )
  }
}

export default Transition

const TransitionContainer = styled.div`
  .swiperMain {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -100vh;
    height: calc(100vh);
    transform-origin: 50% 0% 0;
    background: white;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;

    display: none;

    h1 {
      margin: 0;
      color: #1c1c20;
      font-size: 8vw;
      text-transform: uppercase;
      transform: translateY(10px);
      text-align: center;
      pointer-events: none;

      z-index: 10000;
    }
  }

  .mainContainer {
    min-height: 100vh;
  }
`
