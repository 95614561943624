import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import gsap from 'gsap'

const Cursor = () => {
  let allLinks = []

  let circle = useRef(null)
  let cursor = useRef(null)

  const handleCursorExpand = event => {
    if (
      circle &&
      circle.style.transform !== `scale(1.5) translate(-50%, -50%)`
    ) {
      for (let i = 0; i < allLinks.length; i++) {
        if (
          event.target.className === allLinks[i] ||
          event.target.className === allLinks[i].className
        ) {
          circle.style.transform = `scale(1.5) translate(-50%, -50%)`
        }
      }
    }
  }

  const handleCursorContract = event => {
    if (circle && circle.style.transform !== `scale(1) translate(-50%, -50%)`) {
      for (let i = 0; i < allLinks.length; i++) {
        if (
          event.target.className === allLinks[i] ||
          event.target.className === allLinks[i].className
        ) {
          circle.style.transform = `scale(1) translate(-50%, -50%)`
        }
      }
    }
  }

  const cursorImage = e => {
    if (cursor) {
      const x = e.clientX
      const y = e.clientY
      cursor.style.transform = `translate(${x}px, ${y}px)`
    }
  }

  const getLinks = () => {
    allLinks = document.querySelectorAll(
      '.titleContainer .block, .titleContainer, .cursorTarget, .flip, a, button, video, canvas, .inquiriesLine2'
    )
  }

  useEffect(() => {
    const cursorSetUp = async () => {
      await getLinks()

      document.addEventListener('mousemove', cursorImage)

      document.body.addEventListener('mouseover', event =>
        handleCursorExpand(event)
      )
      document.body.addEventListener('mouseout', event =>
        handleCursorContract(event)
      )
    }

    cursorSetUp()

    gsap.to(cursor, {
      opacity: 1,
      duration: 2,
    })

    return () => {
      document.removeEventListener('mousemove', cursorImage)

      document.body.removeEventListener('mouseover', event =>
        handleCursorExpand(event)
      )
      document.body.removeEventListener('mouseout', event =>
        handleCursorContract(event)
      )
    }
  }, [])

  return (
    <CanvasCursor className="cursor" id={`cursor`} ref={div => (cursor = div)}>
      <div className="circle" ref={div => (circle = div)}></div>
    </CanvasCursor>
  )
}

export default Cursor

const CanvasCursor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10000;
  display: block;
  transition: all 200ms ease-out;
  opacity: 0;

  .circle {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    border-radius: 50%;

    transform: translate(-50%, -50%);
    transform-origin: left center;

    transition: all 0.5s;

    pointer-events: none;
  }

  @media (pointer: coarse), (pointer: none) {
    display: none;
  }
`
